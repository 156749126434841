html, body, #root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* datepicker for mobile start */
.mobile.ant-picker-dropdown {
  left: 0 !important;
  width: 100%;
  max-width: 400px;
}

.mobile .ant-picker-panel {
  width: 100%;
}

.mobile .ant-picker-body {
  padding: 8px 0px !important;
}

.mobile .ant-picker-header-view {
  font-size: 13px;
}

.mobile .ant-picker-content th {
  width: 34px !important;
}

.mobile .ant-picker-content, .mobile .ant-picker-time-panel {
  width: 100% !important;
}

.mobile .ant-picker-date-panel {
  width: 252px !important;
}

.mobile .ant-picker-ranges {
  margin-top: 2px;
}

/* datepicker for mobile end */